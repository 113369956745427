

<template>
	<page>
		<module enable-flex>
			<m-search ref="search" @search="searchHandle">
				<m-search-item label="客户名称">
					<c-input name="name"></c-input>
				</m-search-item>
				<m-search-item label="客户编码">
					<c-input name="code"></c-input>
				</m-search-item>
				<m-search-item label="客户联系人">
					<c-input name="contact_name"></c-input>
				</m-search-item>
				<m-search-item label="联系方式">
					<c-input name="phone"></c-input>
				</m-search-item>
				<m-search-item label="地址">
					<c-input name="address"></c-input>
				</m-search-item>
				<m-search-item label="负责人">
					<c-input name="responsible_person"></c-input>
				</m-search-item>
			</m-search>

			<m-operate>
				<c-button v-if="auth('b_customer_edit')" color="sys" @click="createCustomer">新建客户</c-button>
			</m-operate>

			<c-table ref="table" height="grow">

				<c-table-column
					label="客户名称"
					width="260"
					name="name"
				></c-table-column>

				<c-table-column
					label="客户编码"
					width="100"
					name="code"
				></c-table-column>

				<c-table-column
					label="客户联系人"
					width="90"
					name="contact_name"
				></c-table-column>
				
				<c-table-column
					label="联系方式"
					width="120"
					name="phone"
				></c-table-column>
				
				<c-table-column
					label="地址"
					width="200"
					name="address"
				></c-table-column>
				
				<c-table-column
					label="产品数量"
					width="70"
					name="product_count"
				></c-table-column>
				
				<c-table-column
					label="负责人"
					width="80"
					name="responsible_person"
				></c-table-column>
				
				<c-table-column
					label="创建人"
					width="100"
					name="create_user_realname"
				></c-table-column>
				
				<c-table-column
					label="创建时间"
					width="140"
					name="create_date"
				></c-table-column>

				<c-table-column
					type="button"
					label="操作"
					width="180"
				>
					<template v-slot="{data}">
						<c-table-button v-if="auth('b_customer_edit')" @click="editCustomer(data)">编辑</c-table-button>
						<c-table-button v-if="auth('b_customer_edit')" @click="deleteCustomer(data)">删除</c-table-button>
						<c-table-button v-if="auth('b_customer_view')" @click="productList(data)">关联产品</c-table-button>
					</template>
				</c-table-column>
			</c-table>
		</module>

		<!--新增、编辑 弹框-->
		<c-dialog ref="customerEdit" :title="editState ? '编辑客户' : '新建客户'" width="500" @resolve="submitCustomer">
			<c-form ref="customerForm" unit-width="100%">
				<c-form-item label="客户名称" required>
					<c-input name="name" maxlength="200"></c-input>
				</c-form-item>
				<c-form-item label="客户编码">
					<c-input name="code" maxlength="50"></c-input>
				</c-form-item>
				<c-form-item label="客户联系人">
					<c-input name="contact_name" maxlength="50"></c-input>
				</c-form-item>
				<c-form-item label="联系方式" >
					<c-input name="phone" maxlength="100"></c-input>
				</c-form-item>
				<c-form-item label="地址">
					<c-input name="address" maxlength="500"></c-input>
				</c-form-item>
				<c-form-item label="负责人" >
					<c-input name="responsible_person" maxlength="100"></c-input>
				</c-form-item>
				<c-input type="hidden" name="id"></c-input>
			</c-form>
		</c-dialog>

		<!--关联产品列表 -->
		<c-dialog ref="productDialog" :title="this.customer_name+' - 已关联产品'" width="800">
			<m-search ref="productSearch" @search="searchProductHandle">
				<m-search-item label="产品名称">
					<c-input name="product_name"></c-input>
				</m-search-item>
<!--				<m-search-item label="产品编码">
					<c-input name="product_code"></c-input>
				</m-search-item>-->
			</m-search>
			<m-operate>
				<c-button v-if="auth('b_customer_setproduct')" color="sys" @click="addProduct">新增产品关联</c-button>
			</m-operate>
			<c-table ref="productTable" height="400">
<!--				<c-table-column
					label="产品编码"
					width="120"
					name="product_code"
				></c-table-column>-->
				
				<c-table-column
					label="客户货号"
					width="120"
					name="customer_product_code"
				></c-table-column>
				
				<c-table-column
					label="产品图片/名称"
					width="220"
					name="product_img"
				>
					<template #default="{data}">
					    <div class="flex-center-cross">
					        <c-file v-if="data.product_img" media :src="data.product_img" style="margin-right: 18px;width:40px;height:40px;"></c-file>
							<span>{{data.product_name}}</span>
					    </div>
					</template>
				</c-table-column>

				<c-table-column
					label="单位"
					width="50"
					name="unit"
				></c-table-column>
				<!-- <c-table-column
					label="所属类目"
					width="170"
					name="product_type_name"
				></c-table-column> -->
				
				<c-table-column
					type="button"
					label="操作"
					width="150"
				>
					<template v-slot="{data}">
						<c-table-button v-if="auth('b_customer_setproduct')" @click="deleteProduct(data)">取消关联</c-table-button>
						<c-table-button v-if="auth('b_customer_setproduct')" @click="editCustomerProductCode(data)">修改客户货号</c-table-button>
					</template>
				</c-table-column>
			</c-table>
		</c-dialog>
		
		<!--产品弹框-->
		<product-selector ref="productSelector" multiple @resolve="submitProductConfig"></product-selector>
		
		<!--修改客户货号 弹框-->
		<c-dialog ref="customerProductCodeEdit" title="修改客户货号" width="400" @resolve="submitCustomerProductCode">
			<c-form ref="customerProductCodeForm" unit-width="100%">
				<c-form-item label="客户货号" required>
					<c-input name="customer_product_code" type="text" maxlength="50"></c-input>
				</c-form-item>
				<c-input type="hidden" name="ref_id"></c-input>
			</c-form>
		</c-dialog>

  </page>
</template>

<script>
import productSelector from '@/components/service/product_selector.vue'
import {cryptoJs} from '@deanwu/vue-component-library'
import {mapState} from 'vuex'

export default {
	name: 'm_customer',
	components: {
		productSelector
	},
	data() {
		return {
			editState: false,
			customer_name: ''
		}
	},
	computed: {
		...mapState(['userInfo'])
	},
	mounted() {
		this.$refs.search.search();
	},
	methods: {
		//搜索
		searchHandle(data) {
			this.$refs.table.load({
				url: '/customer/list',
				data
			});
		},
		//新建客户
		createCustomer() {
			this.editState = false;
			this.$refs.customerForm.clear();
			this.$refs.customerEdit.open();
		},
		//编辑客户
		editCustomer(val) {
			this.editState = true;
			this.$refs.customerForm.set(val);
			this.$refs.customerEdit.open();
		},
		//删除客户
		deleteCustomer(val){
			this.$confirm({
				title: '确认',
				message: '确定要删除【'+val.name+'】吗？',  // 提示内容,
				buttonName: '确定',  // 确定按钮文字
				cancelButton: true,  // 是否显示取消按钮
				resolve: () => {
					this.request({
						url: '/customer/delete',
						data: {id:val.id},
						loading: true,
						success: data => {
							this.$message({
							  message: '删除成功',
							  type: 'success'
							});
							this.$refs.table.update();
						}
					});
				},  // 确定按钮回调
				cancel: () => {},  // 取消按钮回调
			});
		},
		//提交保存
		submitCustomer(stop) {
			stop();
			this.$refs.customerForm.submit({
				url: this.editState ? '/customer/edit' : '/customer/add',
				rule: {
					name: '请填写客户名称'
				},
				dataFormatter: data => {
					data.type = 1;
					return data;
				},
				success: (data, res) => {
					this.$message({
						message: '保存成功',
						type: 'success'
					});
					this.$refs.table.update();
					this.$refs.customerEdit.close();
				}
			});
		},
		//查看关联产品
		productList(val){
			this.$refs.productDialog.open();
			this.customer_id = val.id;
			this.customer_name = val.name;
			this.$refs.productSearch.search();
		},
		//搜索已关联产品
		searchProductHandle(data){
			data.type = 1;
			data.customer_id = this.customer_id;
			data.current_customer = 1;
			this.$refs.productTable.load({
				url: '/product/list',
				data
			});
		},
		//新增产品关联-弹框
		addProduct(){
			this.$refs.productSelector.openExists(this.customer_id);
		},
		//新增产品关联-确定
		submitProductConfig(data){
			this.request({
				url: '/customer/set_product',
				data: {
					customer_id: this.customer_id,
					product_ids: Array.from(data, item => {
						return {
							product_id: item.id
						}
					})
				},
				loading: true,
				success: () => {
					this.$refs.productTable.update();
					this.$refs.table.update();
				}
			});
		},
		//取消产品关联
		deleteProduct(val){
			this.$confirm({
				title: '确认',
				message: '确定要取消产品【'+val.product_name+'】的关联吗？',  // 提示内容,
				buttonName: '确定',  // 确定按钮文字
				cancelButton: true,  // 是否显示取消按钮
				resolve: () => {
					this.request({
						url: '/customer/delete_product',
						data: {
							id: val.ref_id
						},
						loading: true,
						success: data => {
							this.$message({
							  message: '取消成功',
							  type: 'success'
							});
							this.$refs.productTable.update();
							this.$refs.table.update();
						}
					});
				},  // 确定按钮回调
				cancel: () => {},  // 取消按钮回调
			});
		},
		//修改客户货号-弹框
		editCustomerProductCode(val){
			this.$refs.customerProductCodeForm.clear();
			this.$refs.customerProductCodeForm.set(val);
			this.$refs.customerProductCodeEdit.open();
		},
		//修改客户货号-提交保存
		submitCustomerProductCode(stop) {
			stop();
			this.$refs.customerProductCodeForm.submit({
				url: '/customer/update_product',
				rule: {
					customer_product_code: '请填写客户货号'
				},
				dataFormatter: data => {
					return data;
				},
				success: (data, res) => {
					this.$message({
						message: '修改客户货号成功',
						type: 'success'
					});
					this.$refs.productTable.update();
					this.$refs.customerProductCodeEdit.close();
				}
			});
		}
  }
}
</script>